<template>
  <div class="box mt-2">
    <div class="has-text-centered mb-3">
      <span class="title">用户登录</span>
      <br/>
      <span class="subtitle">User Login</span>
    </div>
    <div class="columns">
        <div class="column is-5">
          <form>

          <b-field label="UserEmail">
            <b-input v-model="adminUser.userEmail"
                     maxlength="30"></b-input>
          </b-field>

          <b-field label="Password">
            <b-input type="password"
                     v-model="adminUser.password"
                     password-reveal
                     maxlength="30">
            </b-input>
          </b-field>
          <b-field label="记住登录？">
            <b-radio-button v-model="rememberMe"
                            native-value="Yes"
                            type="is-success is-light is-outlined">
              <b-icon icon="check"></b-icon>
              <span>保存登录状态</span>
            </b-radio-button>
            <b-radio-button v-model="rememberMe"
                            native-value="No"
                            type="is-danger is-light is-outlined">
              <b-icon icon="close"></b-icon>
              <span>下次一定</span>
            </b-radio-button>
          </b-field>
          <div class="buttons">
            <Vcode :puzzleScale="0.8" :imgs="Img1" :show="verifyIMG" @success="loginHandler" @close="onClose"/>
            <b-button :disabled="adminUser.password.length==0||adminUser.userEmail.length==0" @click="submit" expanded
                      type="is-success">登录
            </b-button>
          </div>
          </form>

        </div>
      <div class="column is-1"></div>
      <div class="column">
        <section>
          <h5 class="is-size-3 mb-2">免责声明</h5>
          <div class="content is-size-5">
            <ul>
              <li>使用本网站服务，均为公益服务；</li>
              <li>如果因为自己操作，而导致不可预知结果，本站不承担任何后果。 (当然，如果出现问题，想咨询可以联系我)</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>

</template>

<script>
import {post} from "@/untils/request";
import Vcode from "vue-puzzle-vcode";

export default {
  name: "login",
  data() {
    return {
      verifyIMG: false,
      Img1: [
        require("@/assets/verifyIMGs/TinyStart-Small.jpg"),
        require("@/assets/verifyIMGs/PathofMiranda_Traning-Small.jpg"),
        require("@/assets/verifyIMGs/NoName-Small.jpg"),
        require("@/assets/verifyIMGs/poolParty-Small.jpg"),
        require("@/assets/verifyIMGs/milkTea-Small.jpg")
      ],
      rememberMe: "Yes",
      adminUser: {
        userEmail: "",
        password: "",
      },
    }
  },
  components: {
    Vcode,
  },
  methods: {
    loginHandler() {
      post("userAdmin/login", this.adminUser)
        .then(e => {
          if (e.data.length != 0) {
            this.$store.commit("updateUser", e.data)
          }
          this.$buefy.toast.open({
            type: "is-success",
            message: "登录成功，跳转了嗷～～"
          })
          this.$nextTick(() => this.$router.push("/"))
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "登录失败，请检查账号信息"
          })
        })
        .finally(() => this.verifyIMG = false)
    },
    onClose() {
      this.verifyIMG = false;
    },
    submit() {
      this.verifyIMG = true;
    },
  }
}
</script>

<style scoped>

</style>
