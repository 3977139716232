<template>
  <div class="box mt-2">
    <div class="has-text-centered mb-3">
      <span class="title">创造支持</span>
      <br/>
      <span class="subtitle">Support For Creativity</span>
    </div>
    <div class="columns">
      <div class="column">
        <img class="is-full" :src="QQ_PAY"/>
        <p class="content p-5">
          有什么事情，需要说很多内容或者图很多的ヾ(≧▽≦*)o，可以QQ：198330181
        </p>
      </div>
      <div class="column">
        <img class="is-full" :src="Bilibili"/>
        <p class="content p-5">
          有什么事情，需要说很多内容或者图很多的ヾ(≧▽≦*)o，可以QQ：198330181
        </p>
      </div>
      <div class="column">
        <img class="is-full" :src="AiFaDian_PAY"/>
        <p class="content p-5">
          有什么事情，需要说很多内容或者图很多的ヾ(≧▽≦*)o，可以QQ：198330181
        </p>
      </div>
      <div class="column">
        <img class="is-full" :src="Wechat_PAY"/>
        <p class="content p-5">
          有什么事情，需要说很多内容或者图很多的ヾ(≧▽≦*)o，可以QQ：198330181
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      QQ_PAY: require("@/assets/FollowAndPay/QQ_PAY.jpeg"),
      Bilibili: require("@/assets/FollowAndPay/Bilibili.jpeg"),
      AiFaDian_PAY: require("@/assets/FollowAndPay/AiFaDian_PAY.jpeg"),
      Wechat_PAY: require("@/assets/FollowAndPay/Wechat_PAY.jpeg")
    }
  },
}
</script>
