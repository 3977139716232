<template>
  <div>
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false"></b-loading>
    <div
        v-masonry="'containerId'"
        transition-duration="2s"
        item-selector=".item"
        class="masonryWrap">
      <div
          v-for="(item, index) in listIMG"
          :key="index"
          v-masonry-tile
          class="masonryWrap_item item"
      >
        <router-link
            :to="`/imgDetail/${(item.id)}`">
          <div class="card">
            <div class="card-image">
              <img :src="item.src" @load="onLoad(index)" style="object-fit: cover;max-height: 300px"/>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-left is-bottom">
                  <figure class="image is-32x32">
                    <img :src=avatarIMG alt="Placeholder image">
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-6">{{ item.title }}</p>
                  <p class="subtitle is-7">{{ item.author }}</p>
                </div>
              </div>
            </div>
          </div>
        </router-link>

      </div>
    </div>
  </div>

</template>

<script>
// import {get} from "@/untils/request";
// import {productSnowKey} from "@/untils/snowKey"

export default {
  name: "imageWateFall",
  props:["images_info"],
  data() {
    return {
      isLoading:true,
      avatarIMG:require("@/assets/avatar/DefautAvatar.jpg"),
      listIMG:[]
    }
  },
  methods: {
    onLoad(item) {
      this.$redrawVueMasonry('containerId')
      if (item>=this.images_info.length-1) {
        this.$nextTick(()=>{
          this.isLoading=false
        })
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
  watch:{
    images_info: {
      handler(newVal) {
        this.listIMG = newVal
      },
      deep:true,
      immediate:true
    }
  }
}
</script>

<style scoped>
/*分割线*/
.masonryWrap {
  margin: auto;
  min-height: auto;
  width: 100%;
}

.item {
  width: 100%;
  margin: 0 0 8px 0;
}

.item img {
  display: block;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .item {
    width: calc(100% / 3 - 32px);
    margin: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .item {
    width: calc(100% / 4 - 32px);
  }
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: inherit;
  }
}

</style>
