<template>
  <div>
    <image-wate-fall :images_info="IMG_LIST"/>
    <br/>
    <b-pagination
      :total="total"
      v-model="current"
      @change="backToTop"
      order="is-centered"
      per-page=9
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </div>
</template>

<script>
import ImageWateFall from "@/components/DataView/imageWateFall";
import {get} from "@/untils/request"
import {productSnowKey} from "@/untils/snowKey";

export default {
  name: "ImageHost",
  components: {ImageWateFall},
  data() {
    return {
      total: 10,
      perPage: 10,
      current: 1,
    }
  },
  methods: {
    getIMGS(currentPage) {
      let IMG_LIST = []
      get("/imageHost/getImgByPage", {currentPage: currentPage})
        .then(res => {
          this.total = res.data.total
          const list = res.data.records
          for (const k in list) {
            IMG_LIST.push(
              {
                id: productSnowKey(list[k].id),
                src: this.$store.state.apiHost + list[k].thumbnailPath,
                title: list[k].imageName,
                author: list[k].uploadUser
              }
            )
          }
        })
      return IMG_LIST
    },
    backToTop() {
      let timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let isSpeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + isSpeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 50)
    },
  },
  computed: {
    IMG_LIST: function () {
      console.log("===")
      let list = this.getIMGS(this.current)
      console.log(list)
      return list
    }
  },
}
</script>

<style scoped>

</style>
